/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CustomerBaiscDetailsLayout } from "../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { StandadCard } from "../../../../../components/Cards";
import { Box } from "@mui/material";
import { PolicyTabCard } from "../../../../../components/PolicyTabCard/PolicyTabCard";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import {
  clearBasicPolicyState,
  fetchCustomerInfoAsync,
} from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { BreadcrumbProvider } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { batch } from "react-redux";
import { clearUnderWriterState } from "../../../../../redux/underWriter/underWriterActions";
import { clearBusinessPolicyRiskState } from "../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import { clearBusinessPolicyIntrestedState } from "../../../../../redux/businessPolicy/businessPolicyInterested/businessPolicyInterestedActions";
import { clearBusinessPolicyAdditionalState } from "../../../../../redux/businessPolicy/businessPolicyAdditional/businessPolicyAdditionalActions";
import { clearBusinessPolicyCoverageState } from "../../../../../redux/businessPolicy/businessPolicyCoverage/businesPolicyCoverageActions";
import { clearBusinessPolicyRestrutantState } from "../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { clearBusinessPolicyContractorState } from "../../../../../redux/businessPolicy/businessPolicyContractor/businessPolicyContractorActions";
import { clearBusinessFineArtState } from "../../../../../redux/businessPolicy/businessPolicyFineArt/businessPolicyFineArtActions";
import { clearBusinessToolFloaterRiskState } from "../../../../../redux/businessPolicy/businessPolicyToolFloater/businessPolicyToolFloaterActions";
import { clearBusinessEquipmentRiskState } from "../../../../../redux/businessPolicy/businessPolicyEquipment/businessPolicyEquipmentActions";
import { clearBusinessPolicyUnderWritingState } from "../../../../../redux/businessPolicy/businessPolicyUnderWriting/businePolicyUnderWritingActions";
import { clearBusinessPremiumFinanceState } from "../../../../../redux/businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinanceActions";
import { clearBusinessPolicySubscriptionState } from "../../../../../redux/businessPolicy/businessPolicySubscription/businessPolicySubscriptionActions";
import { clearBusinessPolicyGarageCertiicateState } from "../../../../../redux/businessPolicy/businessGarageCertificate/businessGarageCertificateActions";
import { clearBusinessPolicyPropertyInsuranceState } from "../../../../../redux/businessPolicy/businessPropertyInsurance/businessPropertyInsuranceActions";
import { clearBusinessPolicyLiabilityState } from "../../../../../redux/businessPolicy/businessPolicyLiability/businessPolicyLiabilityActions";
import { clearBusinessPolicyMarketingState } from "../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";
import { clearNotesState } from "../../../../../redux/notes/notesActions";

export const BusinessPolicyLayout: React.FC = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const {
    customerPoliycId: savedCustomerPolicyId,
    customerInfo: { loading, error },
  } = useSelector((storeState: IStoreState) => storeState.business.basicDetails);
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMainTab = currentPathname.includes("basic-details");

  const handleStep = (step: string) => () => {
    const isAlreadyInSamePage = currentPathname.includes(step);
    if (!isAlreadyInSamePage) {
      navigate(
        `/business-policy/${customerId}/${step}/${savedCustomerPolicyId}?tab=0`
      );
    }
  };

  const renderComponent = () => {
    if (isMainTab) {
      return <Outlet />;
    } else if (!isMainTab && !customerPolicyId) {
      return <Navigate to={`/customer-overview/${customerId}`} />;
    } else if (!isMainTab && savedCustomerPolicyId) {
      return <Outlet context={[customerId, savedCustomerPolicyId]} />;
    } else {
      console.log("__FALIED____");
      return null;
    }
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearBasicPolicyState());
        dispatch(clearUnderWriterState());
        dispatch(clearBusinessPolicyRiskState());
        dispatch(clearBusinessPolicyIntrestedState());
        dispatch(clearBusinessPolicyAdditionalState());
        dispatch(clearBusinessPolicyCoverageState());
        dispatch(clearBusinessPolicyRestrutantState());
        dispatch(clearBusinessPolicyContractorState());
        dispatch(clearBusinessFineArtState());
        dispatch(clearBusinessToolFloaterRiskState());
        dispatch(clearBusinessEquipmentRiskState());
        dispatch(clearBusinessPolicySubscriptionState());
        dispatch(clearBusinessPremiumFinanceState());
        dispatch(clearBusinessPolicyUnderWritingState());
        dispatch(clearBusinessPremiumFinanceState());
        dispatch(clearBusinessPolicyGarageCertiicateState());
        dispatch(clearBusinessPolicyPropertyInsuranceState());
        dispatch(clearBusinessPolicyLiabilityState());
        dispatch(clearBusinessPolicyMarketingState());
        dispatch(clearNotesState());
      });
    };
  }, []);

  return (
    <>
      <BreadcrumbProvider>
        <CustomerBaiscDetailsLayout customerId={customerId} fromPolicy policyId={customerPolicyId }>
          <StandadCard sx={{ my: 2 }}>
            <Box display={"flex"} flexWrap={"wrap"}>
              <PolicyTabCard
                label="Basic Details"
                onClick={handleStep("basic-details")}
                isSelected={currentPathname.includes("basic-details")}
              />

              <PolicyTabCard
                label="Claims"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("claims-tab")}
                isSelected={currentPathname.includes("claims-tab")}
              />

              <PolicyTabCard
                label="Underwriting"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("coverage-tab")}
                isSelected={currentPathname.includes("coverage-tab")}
              />

              <PolicyTabCard
                label="Questionnaires"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("questionnare-tab")}
                isSelected={currentPathname.includes("questionnare-tab")}
              />

              <PolicyTabCard
                label="Schedules"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("schedule-tab")}
                isSelected={currentPathname.includes("schedule-tab")}
              />
              <PolicyTabCard
                label="Premium Finance"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("others-tab")}
                isSelected={currentPathname.includes("others-tab")}
              />
              <PolicyTabCard
                label="Marketing"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("marketing-tab")}
                isSelected={currentPathname.includes("marketing-tab")}
              />
              {/* <PolicyTabCard
                label="Binder"
                onClick={handleStep("binder-tab")}
                isSelected={currentPathname.includes("binder-tab")}
              /> */}
              {/* <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              /> */}
              <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              />
              <PolicyTabCard
                label="History"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("history-tab")}
                isSelected={currentPathname.includes("history-tab")}
              />
              <PolicyTabCard
                label="Attachments"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("attachments-tab")}
                isSelected={currentPathname.includes("attachments-tab")}
              />
              <PolicyTabCard
                label="Abeyance/Notes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("notes-tab")}
                isSelected={currentPathname.includes("notes-tab")}
              />
              <PolicyTabCard
                label="Business Tracker"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("task-activity-tab")}
                isSelected={currentPathname.includes("task-activity-tab")}
              />
              <PolicyTabCard
                label="eDocs"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edocs-list")}
                isSelected={currentPathname.includes("csio-edocs-list")}
              />

<PolicyTabCard
                label="eDocs/EDI"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("csio-edi-list")}
                isSelected={currentPathname.includes("csio-edi-list")}
              />
            </Box>
          </StandadCard>
          <PageLoader
            loading={loading === LoadState.InProgress}
            error={error ? { message: error } : null}
          >
            {renderComponent()}
          </PageLoader>
        </CustomerBaiscDetailsLayout>
      </BreadcrumbProvider>
    </>
  );
};
