import React from "react";
import { IExpressionTransformationProps } from "./ExpressionTransformation.types";
import { Dialog } from "../Dialogs/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ChevronRight,
  Code,
  ContentCopyOutlined,
  ErrorOutline,
  ExpandMore,
  Folder,
  Functions,
  FunctionsOutlined,
} from "@mui/icons-material";
import { a11yProps } from "../Tabs/utils";
import { TabPanel } from "../Tabs/TabPanel";
import { formatText } from "../../helpers";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { LoadState } from "../../constants/enums";
import { useDispatchWrapper } from "../../hooks";
import {
  fetchExpressionColumnsAsync,
  fetchExpressionFunctionsAsync,
  upsertExpressionFunctionsAsync,
} from "../../redux/expressionTransformation/expressionTransformationActions";
import {
  IExpressTransformFunParams,
  IExpressTransformObject,
  initialExprressionTransformPayload,
} from "../../redux/expressionTransformation/expressionTransformation.types";

import { batch } from "react-redux";
import { useFormik } from "formik";
import CodeMirror, { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import produce from "immer";
import { showMessage } from "../../redux/messages/messagesActions";
import { ExpressionValidateRightPanel } from "./ExpressionValidateRightPanel";

export const ExpressionTransformation: React.FC<
  IExpressionTransformationProps
> = (props) => {
  const { open, data: mappingData, onClose,onComplete } = props;
  const { data, loading, error } = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.funsVars
  );
  const tableName = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.selectedTableName
  );
  const {
    data: variablesList,
    loading: variablesLoading,
    error: variablesError,
  } = useSelector(
    (storeState: IStoreState) => storeState.expressionTransformation.variables
  );


  const [tabSelected, setTabSelected] = React.useState(0);
  const dispatch = useDispatchWrapper();
  const [openValidate, setOpenValidate] = React.useState(false);
  const { values, setFieldValue, handleSubmit, setValues } = useFormik({
    initialValues: mappingData,
    validate: (values) => {},
    onSubmit: (values) => {
       setOpenValidate(true);
    },
  });
  const editorRef = React.useRef<ReactCodeMirrorRef | null>(null);
  const cursorPosRef = React.useRef<any>(0);

  const insertTextAtCursor = (text: string) => {
    if (!editorRef.current) {
      return;
    }
    const editor = editorRef.current.view;
    if (editor) {
      const cursorPosition = editor.state.selection.main.from;
      cursorPosRef.current = cursorPosition;
      const newValues = produce(values, (draftValues) => {
        draftValues.eval_string =
          draftValues.eval_string.slice(0, cursorPosition) +
          text +
          draftValues.eval_string.slice(cursorPosition);
       
      });
      setValues(newValues);
       // Move the cursor position after the inserted text
       setTimeout(() => {
        // Update cursor position after state update
        editor.dispatch({
          selection: {
            anchor: cursorPosition + text.length, // Move cursor to the end of inserted text
            head: cursorPosition + text.length,
          },
        });
      }, 0);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, value: any) => {
    setTabSelected(value);
  };

  const handleLeftButtonsClick = (value: string) => {
    insertTextAtCursor(value);
  };

  const handleClear = () => {
    setFieldValue("eval_string", "");
  };

  const handleVaraibleClick = (value: string) => () => {
    insertTextAtCursor(value);
  };

  const copyToClipboard = (item: string) => async () => {
 
      // console.log(field)
   
        await navigator.clipboard.writeText(item);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: "Text copied successfully!",
          })
        );
      
    
  };

  const handleFuncClick = (
    mainKey: string,
    subKey: string,
    returnType: any,
    params: IExpressTransformFunParams[]
  ) => {
    const newValues = produce(values, (draftValues) => {
      const index = values.libraries.indexOf(mainKey);
      if (index === -1) {
        draftValues.libraries.push(mainKey);
      }

      if (values.eval_string && values.eval_string.length > 0) {
        draftValues.eval_string = draftValues.eval_string + "\n";
      }

      if (returnType) {
        draftValues.eval_string = draftValues.eval_string + "return";
      }
      draftValues.eval_string = draftValues.eval_string + " " + subKey + "(";
      for (const parm of params) {
        draftValues.eval_string =
          draftValues.eval_string + parm.name + ":" + parm.type + ",";
      }
      draftValues.eval_string = draftValues.eval_string.slice(0, -1) + ")";
    });
    setValues(newValues);
  };

  React.useEffect(() => {
    batch(() => {
      dispatch(fetchExpressionFunctionsAsync());
      dispatch(fetchExpressionColumnsAsync());
    });
  }, []);

  return (
    <>
      <Dialog open={open} title="Expression" size="lg" onClose={onClose}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Paper variant="outlined" sx={{ height: 565, overflowY: "auto" }}>
              <Tabs
                value={tabSelected}
                variant="fullWidth"
                onChange={handleTabChange}
              >
                <Tab
                  label="Functions"
                  sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                  iconPosition="start"
                  value={0}
                  icon={<FunctionsOutlined />}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Variables"
                  sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                  iconPosition="start"
                  icon={<Code />}
                  value={1}
                  {...a11yProps(1)}
                />
              </Tabs>
              <List>
                <TabPanel index={0} value={tabSelected}>
                  {loading === LoadState.InProgress && (
                    <Stack
                      minHeight={"300px"}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={25} sx={{ mb: 2 }} />
                        <Typography variant="h5" fontWeight={500}>
                          Loading, please wait...
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {loading === LoadState.Failed && (
                    <Box
                      minHeight={100}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} justifyContent={"center"} mb={1}>
                        <ErrorOutline />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"80%"}
                      >
                        <Typography
                          variant="body1"
                          textAlign={"center"}
                          fontWeight={600}
                        >
                          {error}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {loading === LoadState.Loaded &&
                    Object.keys(data).map((key, index) => {
                      const dataObject = data[key];
                      return (
                        <TreeListItem
                          key={index}
                          mainKey={key}
                          item={dataObject}
                          onClick={handleFuncClick}
                        />
                      );
                    })}
                </TabPanel>
                <TabPanel index={1} value={tabSelected}>
                  {variablesLoading === LoadState.InProgress && (
                    <Stack
                      minHeight={"300px"}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={25} sx={{ mb: 2 }} />
                        <Typography variant="h5" fontWeight={500}>
                          Loading, please wait...
                        </Typography>
                      </Box>
                    </Stack>
                  )}
                  {variablesLoading === LoadState.Failed && (
                    <Box
                      minHeight={400}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} justifyContent={"center"} mb={1}>
                        <ErrorOutline />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"100%"}
                      >
                        <Typography
                          variant="body1"
                          textAlign={"center"}
                          fontWeight={600}
                        >
                          {variablesError}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {variablesLoading === LoadState.Loaded &&
                    variablesList.map((item) => {
                      return (
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pr={1}>
                       <ListItemButton onClick={handleVaraibleClick(item)}>
                          <ListItemIcon>
                            <Code />
                          </ListItemIcon>
                          <ListItemText
                            primary={item}
                            sx={{
                              "& span": {
                                fontSize: "0.99rem",
                                textOverflow: "ellipsis",
                                width: '80%',
                                overflow: "hidden",
                                wordWrap: "nowrap"
                              },
                            }}
                          />
                        </ListItemButton>
                        <ContentCopyOutlined
                      color={"primary"}
                      sx={{ cursor: "pointer" }}
                      onClick={copyToClipboard(item)}
                    />
                        </Stack>
                        
                      );
                    })}
                </TabPanel>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper
              variant="outlined"
              sx={{ height: 320, width: "100%", overflow: "auto" }}
            >
              <CodeMirror
                ref={editorRef}
                value={values.eval_string || ""}
                height="320px"
                theme="dark"
                extensions={[javascript()]}
               autoFocus={true}
            
                onChange={(value: any) =>
                  setFieldValue("eval_string", value || "")
                }
              />
              {/* <Editor
                height="100%"
                width={"100%"}
                defaultLanguage="javascript"
                value={values.eval_string || ""}
                theme="vs-dark" // Use the dark theme
                onChange={(value) => setFieldValue("eval_string", value || "")}
                options={{
                  automaticLayout: true, // Adjust layout when the window is resized
                  fontSize: 18, // Set font size
                }}
              /> */}
            </Paper>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={9}>
                <Paper variant="outlined" sx={{ height: "100%", padding: 2 }}>
                  <Grid container spacing={1}>
                    {[
                      { label: "AND", value: "&" },
                      { label: "OR", value: "||" },
                      { label: "NOT", value: "!" },
                    ].map((item) => {
                      return (
                        <Grid item xs={4}>
                          <ExpenssionButton
                            label={item.label}
                            value={item.value}
                            onClick={handleLeftButtonsClick}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Paper variant="outlined" sx={{ mt: 1 }}>
                    <Grid container spacing={1}>
                      {[
                        { label: "(", value: "(" },
                        { label: ")", value: ")" },
                        { label: "<", value: "<" },
                        { label: ">", value: ">" },
                        { label: "=", value: "=" },
                        { label: "!=", value: "!=" },
                        { label: "+", value: "+" },
                        { label: "-", value: "-" },
                        { label: "<=", value: "<=" },
                        { label: ">=", value: ">=" },
                        { label: "%", value: "%" },
                        { label: "||", value: "||" },
                        { label: "*", value: "*" },
                        { label: "/", value: "/" },
                        { label: "'", value: "'" },
                        { label: `"`, value: '"' },
                        { label: `,`, value: "," },
                        { label: `:`, value: ":" },
                      ].map((item) => {
                        return (
                          <Grid item xs={2}>
                            <ExpenssionButton
                              label={item.label}
                              value={item.value}
                              onClick={handleLeftButtonsClick}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      spacing={1}
                    ></Stack>
                  </Paper>
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    spacing={1}
                  ></Stack>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper variant="outlined" sx={{ p: 2, height: "100%" }}>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    spacing={1}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{ fontSize: "1.1rem" }}
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
            
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ fontSize: "1.1rem" }}
                      onClick={()=>handleSubmit({} as any)}
                    >
                      Validate & Save
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ fontSize: "1.1rem" }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="warning"
                      sx={{
                        color: "#000",
                        fontWeight: 500,
                        fontSize: "1.1rem",
                      }}
                      fullWidth
                    >
                      Create Variable
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {openValidate &&
        <ExpressionValidateRightPanel
          open={open}
          data={values}
          onClose={()=> setOpenValidate(false)}
          onComplete={()=>{
            setOpenValidate(false);
            onComplete();
          }}

        />
      }
    </>
  );
};

const TreeListItem: React.FC<{
  item: IExpressTransformObject;
  mainKey: string;
  onClick: (
    mainKey: string,
    subKey: string,
    returnType: any,
    params: IExpressTransformFunParams[]
  ) => void;
}> = ({ item, mainKey, onClick }) => {
  const [open, setOpen] = React.useState(false);
  const keys = Object.keys(item);

  const handleToogle = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleToogle}>
        {open ? <ExpandMore /> : <ChevronRight />}
        <ListItemIcon>
          <Folder color="warning" />
        </ListItemIcon>
        <ListItemText
          primary={mainKey}
          sx={{
            "& span": {
              fontSize: "1.1rem",
              fontWeight: 500,
            },
          }}
        />
      </ListItemButton>

      {keys.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {keys.map((childKey, index) => {
              const params = item[childKey].params;
              return (
                <ListItemButton
                  key={childKey}
                  sx={{ pl: 6 }}
                  onClick={() =>
                    onClick(mainKey, childKey, item[childKey].return, params)
                  }
                >
                  <ListItemIcon>
                    <Functions />
                  </ListItemIcon>
                  <ListItemText primary={childKey} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

const ExpenssionButton: React.FC<{
  label: string;
  value: string;
  onClick: (value: string) => void;
}> = ({ label, value, onClick }) => {
  return (
    <Button
      variant="contained"
      fullWidth
      sx={{ fontSize: "1.1rem",  }}
      onClick={() => onClick(value)}
    >
      {label}
    </Button>
  );
};
